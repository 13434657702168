import React from 'react';
import { observer } from 'mobx-react';

import { SignInForm } from '~/account/sign-in/Components/SignInForm';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { AsyncFormSubmitLoading } from '~/util/formz/Components/plugins/async-form-submit/AsyncFormSubmitLoading';

import styles from '~/account/sign-in/Components/sign-in-favorites-content.module.scss';
import { Button } from '~/components/Buttons/Components/Button';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';

export const SignInFavoritesContent = observer((props) => {
	const {
		store: {
			form,
			isSubmitted,
			isSubmitProcessing,
			isAuthenticated,
			callback,
			defaults: formOverrides,
		} = {},
		modalStore: {
			openModal,
		} = {},
	} = props;

	const createAccountClick = (e) => {
		e.preventDefault();

		openModal('createAccountFavorites', {
			callback,
			formOverrides,
			modalOptions: {
				trLinkEventCompName: 'top links',
				trLinkEventCompType: 'header navigation',
			}
		});
	};

	if ((isSubmitted && isAuthenticated) || isSubmitProcessing) {
		return (
			<div className={styles['spinner-container']}>
				<LoadingSpinner
					isLoading
					minHeight="250px"
					parentSettings={{ width: '100%', height: '250px' }}
				/>
			</div>
		);
	}

	return (
		<div className="ajax-submit-wrapper tw-relative">
			<AsyncFormSubmitLoading form={form} />
			<div className="md:tw-grid tw-grid-cols-2 tw-items-center">
				<div className="tw-p-6 sm:tw-mb-6">
					<h1 className="tw-heading-1 tw-mb-3">Save Your Favorites</h1>
					<div className="tw-heading-5 tw-mb-6">Sign in to save your favorites and make sure they're here next time you visit.</div>
					<SignInForm {...props} />
				</div>
				<div className="tw-grid tw-h-full tw-bg-gray-600 tw-items-center tw-justify-center tw-p-6">
					<div>
						<div className="tw-heading-6">A Room & Board account lets you:</div>
						<ul className={styles.favoritesList}>
							<li>Save your Favorites</li>
							<li>Create and organize multiple lists</li>
							<li>Share your lists with a shopping partner</li>
							<li>Check out faster</li>
						</ul>
						<Button variant={ButtonVariant.Hollow} color={ButtonColor.Gray} data-qa="create-an-account-button" onClick={createAccountClick}>
							Create an Account
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
});
