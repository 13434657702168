import React from 'react';
import { observer } from 'mobx-react';

import styles from './sign-in-form.module.scss';

import { isMobile } from '~/global/global.constants';
import { AsyncFormSubmitError } from '~/util/formz/Components/plugins/async-form-submit/AsyncFormSubmitError';
import { EmailField } from '~/util/formz/Components/fields/EmailField';
import { PasswordField } from '~/util/formz/Components/fields/PasswordField';
import { CheckboxField } from '~/util/formz/Components/fields/CheckboxField';

export const SignInForm = observer(({ store = {} }) => {
	const {
		form,
		form: {
			reactProps,
		},
		hasSubmitError,
	} = store;
	const errorMessage = (
		<div
			className={styles.signInError}
			data-qa="sign-in-error"
			role="alert"
		>
			<p>We cannot find an account with that email address and password combination.</p>
			<p>Please try again or <a href="/account/reset-password/">
				reset your password</a>.</p>
		</div>
	);

	const {
		key: formKey,
		...formReactProps
	} = reactProps;

	return (
		<form
			suppressHydrationWarning
			key={formKey}
			{...formReactProps}
		>
			<AsyncFormSubmitError
				showFormLevelErrors={hasSubmitError}
				form={form}
				errorAttrs={{
					'className': 'server-error',
					'aria-describedby': form.id || '',
				}}
			>
				{errorMessage}
			</AsyncFormSubmitError>

			<EmailField field={form.fields.username} />

			<PasswordField
				field={form.fields.password}
				afterValidation={
					<a
						key="forgot-your-password"
						className="forgot-your-password"
						data-qa="forgot-your-password"
						href="/account/reset-password"
					>
						Forgot your password?
					</a>
				}
				showPasswordField={form.fields.showPassword}
			/>
			{
				!isMobile && <CheckboxField field={form.fields.rememberMe} />
			}
			<input
				id="signInSubmitButton"
				data-qa="signInSubmitButton"
				type="submit"
				className="Button"
				key="delivery-referral-submit"
				value="Sign In"
			/>
		</form>
	);
});
