/* eslint jsx-a11y/label-has-for: "off" */

import { useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Label } from '~/util/formz/Components/Label';
import { Validation } from '~/util/formz/Components/Validation';
import { useSafeSpread } from '~/hooks/useSafeSpread';
import styles from '#/account/sign-in/sign-in.module.scss';

export const PasswordField = observer(function PasswordField(props) {
	const [state, setState] = useState({ showPassword: false });
	const [showPasswordFieldProps] = useSafeSpread(props?.showPasswordField?.fieldData?.reactProps ?? {});
	const [showPasswordControlProps] = useSafeSpread(props?.showPasswordField?.fieldData?.control?.reactProps ?? {});
	const type = state.showPassword ? 'text' : 'password';
	const { field } = props;
	const hasError = Boolean(field?.plugins?.validationMessage?.errorMessage);
	const validationId = field?.plugins?.validationMessage?.reactProps?.id;
	let inputProps = {
		...field.control.reactProps,
		'aria-invalid': hasError,
		type,
	};

	if (hasError) {
		inputProps = {
			...inputProps,
			'aria-describedby': validationId,
		};
	}

	const [restOfInputProps, inputKey] = useSafeSpread(inputProps);
	const [labelProps] = useSafeSpread(props);
	const [restOfDivProps, divKey] = useSafeSpread(field.field.reactProps);

	return (
		<div key={divKey} { ...restOfDivProps }>
			{props.beforeLabel}
			<Label key={inputKey} {...labelProps} />
			{props.afterLabel}
			{props.beforeControl}
			<div className={classNames(styles['password-container'], 'password-container')}>
				<input key={inputKey} {...restOfInputProps} />
				<button
					{...showPasswordFieldProps} {...showPasswordControlProps}
					type="button"
					data-tr-link-event-track={false}
					data-qa="show-password"
					onClick={(e) => {
						e.preventDefault();
						setState({ showPassword: !state.showPassword });
					}}
				>
					{
						state.showPassword
							? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.412 19.501" width="22" height="18" role="img" aria-labelledby="hide-password-svg-title">
								<title id="hide-password-svg-title">Hide password</title>
								<g id="Group_48" data-name="Group 48" transform="translate(-1191.876 -202.249)">
									<path id="Path_21" data-name="Path 21" d="M3.2,9.034s4.963,5.747,11.058,5.834c6.1,0,11.058-5.834,11.058-5.834S20.7,3.2,14.258,3.2,3.2,9.034,3.2,9.034Z" transform="translate(1189.324 203.132)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
									<ellipse id="Ellipse_8" data-name="Ellipse 8" cx="5.486" cy="5.486" rx="5.486" ry="5.486" transform="translate(1198.184 206.68)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
									<ellipse id="Ellipse_9" data-name="Ellipse 9" cx="2.003" cy="2.003" rx="2.003" ry="2.003" transform="translate(1201.667 210.163)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
								</g>
							</svg>
							: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.412 19.501" width="22" height="18" role="img" aria-labelledby="show-password-svg-title">
								<title id="show-password-svg-title">Show password</title>
								<g id="Group_51" data-name="Group 51" transform="translate(-1189.351 -202.249)">
									<g id="Group_48" data-name="Group 48" transform="translate(-2.524 -0.332)">
										<path id="Path_21" data-name="Path 21" d="M3.2,9.034s4.963,5.747,11.058,5.834c6.1,0,11.058-5.834,11.058-5.834S20.7,3.2,14.258,3.2,3.2,9.034,3.2,9.034Z" transform="translate(1189.324 203.132)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
										<ellipse id="Ellipse_8" data-name="Ellipse 8" cx="5.486" cy="5.486" rx="5.486" ry="5.486" transform="translate(1198.184 206.68)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
										<ellipse id="Ellipse_9" data-name="Ellipse 9" cx="2.003" cy="2.003" rx="2.003" ry="2.003" transform="translate(1201.667 210.163)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
									</g>
									<line id="Line_18" data-name="Line 18" x1="11" y2="19" transform="translate(1195.5 202.5)" fill="none" stroke="#000" strokeWidth="1" />
								</g>
							</svg>
					}
				</button>
				<Validation {...props} />
				{props.afterValidation}
			</div>
			{props.afterControl}
		</div>
	);
});
