import React from 'react';
import { observer } from 'mobx-react';

import { CreateAccountForm } from '~/account/create/Components/CreateAccountForm';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { AsyncFormSubmitLoading } from '~/util/formz/Components/plugins/async-form-submit/AsyncFormSubmitLoading';

import styles from '~/account/create/Components/create-account-favorites-content.module.scss';

export const CreateAccounFavoritesContent = observer((props) => {
	const {
		store: {
			isSubmitted,
			isSubmitProcessing,
			form,
		} = {},
	} = props;

	if (isSubmitted || isSubmitProcessing) {
		return (
			<div className={styles['spinner-container']}>
				<LoadingSpinner isLoading parentSettings={{ width: '100%', height: '500px' }} />
			</div>
		);
	}

	return (
		<div className="ajax-submit-wrapper tw-relative">
			<AsyncFormSubmitLoading form={form} />

			<div className="md:tw-grid tw-grid-cols-2 tw-items-center">
				<div className="tw-p-6 sm:tw-mb-6">
					<h1 className="tw-heading-1">Create an Account</h1>
					<CreateAccountForm {...props} />
				</div>

				<div className="tw-grid tw-h-full tw-bg-gray-600 tw-items-center tw-justify-center tw-p-6">
					<div>
						<div className="tw-heading-6">
							A Room & Board account lets you:
						</div>
						<ul className={styles.favoritesList}>
							<li>Save your Favorites</li>
							<li>Create and organize multiple lists</li>
							<li>Share your lists with a shopping partner</li>
							<li>Check out faster</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
});
